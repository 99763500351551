import { useEffect, useMemo, useState } from 'react'
import useAvatarMetadata, { type InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import useAvatarStore, { type AvatarState } from '@modules/inventory/hooks/useAvatarStore'
import useBaseSkins from './useBaseSkins'
import useDnaItems from './useDnaItems'

interface Props {
  isDNA?: boolean
  isDNATraits?: boolean
}

const useInventoryItems = ({ isDNA = false, isDNATraits = false }: Props) => {
  const { baseSkins } = useBaseSkins({ enabled: isDNA })
  const { dnaItems, isDnaItemsLoading } = useDnaItems({ enabled: isDNATraits })
  const { tokenMetadata, isTokenMetadataLoading } = useAvatarMetadata()

  const userContractItems = tokenMetadata?.inventory?.items

  const onChainItems = useMemo(
    () => (isDNA ? baseSkins : (userContractItems as unknown as InventoryItem[])),
    [baseSkins, userContractItems, isDNA]
  )

  const items = useMemo(() => {
    if (isDNATraits) {
      return dnaItems ?? []
    } else {
      return onChainItems ?? []
    }
  }, [onChainItems, dnaItems, isDNATraits])

  const setItems = useAvatarStore((state: AvatarState) => state.setItems)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (items?.length) {
      setItems(items as InventoryItem[])
      setLoading(false)
    }
  }, [items])

  const isItemsLoading = isDNATraits ? isDnaItemsLoading : loading || isTokenMetadataLoading

  return {
    inventoryItems: (items as InventoryItem[]) || [],
    isInventoryItemsLoading: isItemsLoading
  }
}

export default useInventoryItems
