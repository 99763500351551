/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Text, Group, Stack } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import useInventoryItems from '@hooks/inventory/useInventoryItems'
import CTASection from './components/CTASection'
import DNATraitsInventory from './components/DNATraitsInventory'
import Equipping from './components/Equipping'
import InventorySlot from './components/InventorySlot'
import MobileInventory from './components/MobileInventory'
import useAvatarActions from './hooks/useAvatarActions'
import { SlotType } from './hooks/useAvatarStore'
import useEquipItems from './hooks/useEquipItems'
import useGroupedItems from './hooks/useGroupedItems'

const DNA_SLOT_TYPES = [SlotType.Body]
const DNA_TRAITS_SLOT_TYPES = [SlotType.Eyes, SlotType.Eyebrows, SlotType.Hair, SlotType.FacialHair]

type InventoryModuleProps = {
  isDNA?: boolean
  isDNATraits?: boolean
  isMobile?: boolean
  hideActionMenu?: boolean
}

const InventoryModule = forwardRef(
  ({ isDNA = false, isDNATraits = false, isMobile = false, hideActionMenu }: InventoryModuleProps, ref: any) => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { inventoryItems: items, isInventoryItemsLoading } = useInventoryItems({
      isDNA: isDNA,
      isDNATraits: isDNATraits
    })

    const {
      selectedItem,
      setSelectedItem,
      nextStep,
      selectedElementRef,
      setRotating,
      previewItems,
      uploadAvatar,
      clearCategory,
      isAbleToShareAvatar,
      selectedOnboardingSkin
    } = useAvatarActions(isDNA, searchParams)

    const handleSuccessEquip = () => {
      setRotating(true)
      uploadAvatar(ref?.current, 2000)
    }
    const { isEquippingItems, isEquipSuccess, setIsEquipSuccess, equipItems } = useEquipItems({
      onCallback: handleSuccessEquip
    })
    const groupedItems = useGroupedItems(items, previewItems, isDNA)

    const slotTypesToMap = isDNATraits ? DNA_TRAITS_SLOT_TYPES : isDNA ? DNA_SLOT_TYPES : TENANT_CONFIG.sortedSlots

    if (isMobile) {
      return (
        <MobileInventory
          groupedItems={groupedItems}
          mint={equipItems}
          isLoading={isEquippingItems}
          nextStep={nextStep}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          hideActionMenu={hideActionMenu}
          slotTypesToMap={slotTypesToMap}
        />
      )
    }
    return isEquippingItems || isEquipSuccess ? (
      <Equipping
        isEquipping={isEquippingItems}
        onEquipInventory={equipItems}
        onEquipDone={() => {
          setIsEquipSuccess(false)
          navigate('/')
        }}
        displayShareAvatar={!!isAbleToShareAvatar && !isEquippingItems && isEquipSuccess}
      />
    ) : (
      <>
        <Stack w='full' gap='md' py='md'>
          {isDNATraits ? (
            <DNATraitsInventory
              groupedItems={groupedItems}
              nextStep={nextStep}
              clearCategory={clearCategory}
              isDNA={isDNA}
              selectedOnboardingSkin={selectedOnboardingSkin}
              slotTypesToMap={slotTypesToMap}
              isInventoryItemsLoading={isInventoryItemsLoading}
            />
          ) : (
            slotTypesToMap.map((key: any) => {
              const targetSlotType = SlotType[key] as any
              const itemsPerGroup = groupedItems[SlotType[targetSlotType] as any]

              return (
                <Stack gap='sm' key={key} ref={searchParams.get('slot') === SlotType[key] ? selectedElementRef : null}>
                  <Text>{SlotType[key]}</Text>
                  <Group wrap='wrap'>
                    {itemsPerGroup ? (
                      itemsPerGroup.map((inventoryItem: InventoryItem, index: number) => (
                        <InventorySlot
                          key={`${inventoryItem.tokenId}-${index}`}
                          image={inventoryItem.imageUrl}
                          count={inventoryItem.count}
                          onClick={() => (inventoryItem.isEquipped ? clearCategory(key) : nextStep(inventoryItem))}
                          isActive={
                            isDNA ? selectedOnboardingSkin?.glbUrl === inventoryItem.glbUrl : !!inventoryItem.isEquipped
                          }
                          testId={`avatar-inventory-slot-${index}`}
                        />
                      ))
                    ) : (
                      <InventorySlot isLoading={isInventoryItemsLoading} />
                    )}
                  </Group>
                </Stack>
              )
            })
          )}
        </Stack>
        {!hideActionMenu && (
          <CTASection
            displayBack={!!selectedItem}
            onBack={() => {
              if (selectedItem) {
                setSearchParams({ slot: SlotType[selectedItem.slotType as SlotType] })
                setSelectedItem(null)
              }
            }}
            onClose={() => navigate('/')}
            onEquip={equipItems}
            isEquipping={isEquippingItems}
          />
        )}
      </>
    )
  }
)
InventoryModule.displayName = 'InventoryModule'
export default InventoryModule
