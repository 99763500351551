import { useState, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'summon-ui/intl'
import { Button, Group, Paper, ScrollArea, Carousel } from 'summon-ui/mantine'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import useAvatarStore, { SlotType } from '../hooks/useAvatarStore'
import InventorySlot from './InventorySlot'

type MobileInventoryProps = {
  groupedItems: { [x: string]: InventoryItem[] }
  mint: () => Promise<void>
  isLoading: boolean
  hideActionMenu?: boolean
  nextStep: (item: InventoryItem, color?: string) => void
  selectedItem: InventoryItem | null
  setSelectedItem: Dispatch<SetStateAction<InventoryItem | null>>
  isDNA?: boolean
  slotTypesToMap: SlotType[]
}

const MobileInventory = ({
  groupedItems,
  mint,
  isLoading,
  nextStep,
  hideActionMenu,
  isDNA,
  slotTypesToMap
}: MobileInventoryProps) => {
  const t = useTranslation()
  const [selectedCategory, setSelectedCategory] = useState<number | null>(slotTypesToMap[0])
  const { selectedOnboardingSkin } = useOnboardingStorage()

  const { clearCategory } = useAvatarStore()
  return (
    <>
      <ScrollArea pb='xs'>
        <Group wrap='nowrap' gap='0' top='0'>
          {slotTypesToMap.map((key: SlotType) => (
            <Button
              key={key}
              variant={selectedCategory === key ? 'outline' : 'transparent'}
              size='xs'
              miw={120}
              onClick={() => {
                setSelectedCategory(key)
              }}
            >
              {SlotType[key]}
            </Button>
          ))}
        </Group>
      </ScrollArea>

      {selectedCategory && (
        <>
          <Carousel
            key={selectedCategory}
            slideSize='33.333%'
            withControls={false}
            slideGap='xs'
            align='start'
            slidesToScroll={2}
          >
            {groupedItems[selectedCategory]?.length ? (
              groupedItems[selectedCategory]?.map((item, index) => (
                <Carousel.Slide key={`${item.tokenId}-${index}`}>
                  <InventorySlot
                    key={`${item.tokenId}-${index}`}
                    image={item.imageUrl}
                    onClick={() => (item.isEquipped ? clearCategory(item.slotType as SlotType) : nextStep(item))}
                    isActive={isDNA ? selectedOnboardingSkin?.glbUrl === item.glbUrl : !!item.isEquipped}
                  />
                </Carousel.Slide>
              ))
            ) : (
              <Carousel.Slide>
                <InventorySlot />
              </Carousel.Slide>
            )}
          </Carousel>
          {!hideActionMenu && (
            <Paper pos='sticky' w='inherit' bottom={8}>
              <Button fullWidth onClick={mint} loading={isLoading}>
                {t('Equip Gear')}
              </Button>
            </Paper>
          )}
        </>
      )}
    </>
  )
}

export default MobileInventory
