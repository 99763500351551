import type { ReactNode } from 'react'
import { Spinner, Table, type ResponsiveList, type TableColumn } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Badge, Flex, Box, Center, Title, Avatar } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import useLineaGuildLeaderboard from './hooks/useLineaGuildLeaderboard'
import type { LineaGuildLeaderboardTableElement } from './types'

type TableKeys = 'rank' | 'name' | 'points' | 'members'
type LineaLeaderboardGuildName = 'FrogwifcatLB' | 'EFrogLB' | 'FoxyLB' | 'LinusLB'

const getGuildImgUrl = (leaderboardLineaGuildName: LineaLeaderboardGuildName) => {
  const guildImgUrlMaps = {
    FrogwifcatLB: 'frogwifcat',
    EFrogLB: 'efrogs',
    FoxyLB: 'foxy',
    LinusLB: 'linus'
  }

  return guildImgUrlMaps[leaderboardLineaGuildName]
}

const NAME_COL_W = 400
const POINTS_COL_W = 200
const RANK_COL_W = 70

const LineaGuildLeaderboardTable = () => {
  const t = useTranslation()
  const { leaderboard, totalUsersCount, isLoading } = useLineaGuildLeaderboard(50)

  const renderRank = (value: number): ReactNode => <Center>{value || `>${totalUsersCount}`}</Center>

  const renderEnemiesKilled = (value: number): ReactNode => (
    <Center>
      <Badge data-testid='linea-leaderboard-table-points-label' color='green.6' miw={55}>
        {value}
      </Badge>
    </Center>
  )

  const renderNameInfo = (name: LineaGuildLeaderboardTableElement['name']): ReactNode => {
    return (
      <Flex align='center' justify='left' gap='md' h={48}>
        <Flex align='center' justify='left' gap='sm'>
          <Avatar
            data-testid='linea-guild-leaderboard-table-avatar-image'
            src={`/images/lootdrop/lineaGuilds/${getGuildImgUrl(name as LineaLeaderboardGuildName)}.png`}
            className='shadow-xl size-10'
          />
          <Box data-testid='linea-guild-leaderboard-table-wallet-label'>{name}</Box>
        </Flex>
      </Flex>
    )
  }

  const columns: TableColumn<LineaGuildLeaderboardTableElement, TableKeys>[] = [
    {
      name: '#',
      key: 'rank',
      sort: (a, b) => a.rank - b.rank,
      render: (value) => renderRank(value as number),
      width: RANK_COL_W
    },
    {
      name: t('Name'),
      key: 'name',
      render: (value) => renderNameInfo(value as LineaGuildLeaderboardTableElement['name']),
      width: NAME_COL_W
    },
    {
      name: t('Members'),
      key: 'members',
      sort: (a, b) => a.members - b.members
    },
    {
      name: t('Awakening Points'),
      key: 'points',
      render: (value) => renderEnemiesKilled(value as number),
      sort: (a, b) => a.points - b.points,
      width: POINTS_COL_W
    }
  ]

  const responsiveList: ResponsiveList<LineaGuildLeaderboardTableElement, TableKeys> = {
    name: {
      key: 'name',
      name: 'Name',
      renderElementKey: (name) => (
        <Flex align='center' justify='left' gap='md' h={48}>
          <Flex align='center' justify='left' gap='sm'>
            <Avatar
              data-testid='linea-guild-leaderboard-table-avatar-image'
              src={`/images/lootdrop/lineaGuilds/${getGuildImgUrl(name as LineaLeaderboardGuildName)}.png`}
              className='shadow-xl size-10'
            />
            <Box data-testid='linea-guild-leaderboard-table-wallet-label'>{name}</Box>
          </Flex>
        </Flex>
      ),
      renderElementValue: () => null
    },
    rank: {
      key: 'rank',
      name: '#',
      renderElementKey: () => <>{t('Rank')}</>,
      renderElementValue: (value) => <>{value}</>
    },
    members: {
      key: 'members',
      name: 'Members',
      renderElementKey: () => <>{t('Multiplier')}</>,
      renderElementValue: (value) => value
    },
    points: {
      key: 'points',
      name: 'Awakening Points',
      renderElementKey: () => <>{t('Awakening Points')}</>,
      renderElementValue: (value) => (
        <Center>
          <Badge color='green.6'>
            <Title order={6}>{value}</Title>
          </Badge>
        </Center>
      )
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  if (leaderboard === null || (!leaderboard.length && !isLoading)) {
    return (
      <Center h='100%' w='100%'>
        <EmptyState
          title={t('No Leaderboard Found')}
          description={t('We can’t find any activity, come back later')}
          testId='leaderboard'
        />
      </Center>
    )
  }

  return (
    <Box>
      <Table<LineaGuildLeaderboardTableElement, TableKeys, TableKeys>
        data={leaderboard}
        dataAlignment={{ rank: 'center', name: 'left', points: 'center', members: 'center' }}
        columns={columns}
        responsiveList={responsiveList}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default LineaGuildLeaderboardTable
