import { create } from 'zustand'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'

export interface PreviewInventoryItem extends InventoryItem {
  isPreview?: boolean
}
export enum SlotType {
  Unknown = 0,
  Legwear = 1,
  Eyewear = 2,
  Eyebrows = 3,
  Eyes = 4,
  FacialHair = 5,
  Footwear = 6,
  Hair = 7,
  Headwear = 8,
  Skins = 10,
  Socks = 11,
  Tops = 12,
  Body = 13,
  Hands = 14,
  Mouth = 15,
  Back = 17,
  Outerwear = 18,
  Companion = 19
}

export interface AvatarState {
  items: PreviewInventoryItem[] | []
  previewItems: PreviewInventoryItem[] | []
  equipItem: (item: PreviewInventoryItem) => void
  unequipItem: (item: PreviewInventoryItem) => void
  setItems: (items: InventoryItem[]) => void
  setRotating: (rotating: boolean) => void
  rotating: boolean
  resetPreviewItems: () => void
  clearCategory: (slot: number) => void
}

const useAvatarStore = create<AvatarState>()((set, get) => ({
  items: [],
  previewItems: [],
  rotating: false,
  setRotating: (rotating: boolean) => set({ rotating }),
  setItems: (items: InventoryItem[]) => {
    useOnboardingStorage.setState({
      selectedOnboardingSkin: items.find((item) => item.slotType === SlotType.Body)
    })
    set({
      items: items.map((item) => (item.slotType === SlotType.Body ? { ...item, isEquipped: false } : item)),
      previewItems: get().items.filter((item) => item.slotType !== SlotType.Body)
    })
  },
  unequipItem: (item: PreviewInventoryItem) => {
    const newItems = get().previewItems.map((i) => (i.tokenId === item.tokenId ? { ...item, isPreview: false } : i))
    set({ previewItems: newItems })
  },
  clearCategory: (slot) => {
    const newItems = get().previewItems.map((item) => (item.slotType === slot ? { ...item, isEquipped: false } : item))
    set({ previewItems: newItems })
  },
  equipItem: (item: PreviewInventoryItem) => {
    const newItems = get().previewItems.map((previewItem) => {
      const sameSlotTypeItems = previewItem.slotType === item.slotType

      return sameSlotTypeItems
        ? previewItem.tokenId === item.tokenId
          ? { ...item, isEquipped: true }
          : { ...previewItem, isEquipped: false }
        : previewItem
    })
    set({ previewItems: newItems })
  },
  resetPreviewItems: () =>
    set({
      previewItems: get().items.filter((item) => item.slotType !== SlotType.Body)
    })
}))

export default useAvatarStore
