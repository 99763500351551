import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// @ts-ignore
// eslint-disable-next-line import/extensions
import WebGL from 'three/addons/capabilities/WebGL.js'
import clsx from 'clsx'
import { useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Box, Carousel, Drawer, Flex, Stack, Title, useMediaQuery } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import { TENANT_CONFIG } from '@config'
import { LevelUpVersion } from '@config/enum'
import Rewards from '@features/home/components/rewards'
import AvatarPreview from '@modules/avatar'
import InventoryModule from '@modules/inventory'
import RecommendedCategory from '../components/RecommendedCategory'
import LevelInfo from '../components/levelUp/LevelInfo'

const NO_WEBGL_ENABLED_MESSAGE =
  'It looks like you don’t have WebGL installed in your browser so your avatar can’t be shown. Try switching to a different browser or enabling WebGL to resolve this error.'

enum AvatarViewTabs {
  Inventory = 'inventory',
  Rewards = 'rewards'
}

const AvatarView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const t = useTranslation()
  const isTabletOrMobile = useMediaQuery('(max-width: 680px)')
  const isInventoryOpen = id === AvatarViewTabs.Inventory
  const isRewardsOpen = id === AvatarViewTabs.Rewards
  const ref = useRef<HTMLCanvasElement>(null)
  const isMobile = useMediaScreen({ breakpoint: 'mobile' })

  if (WebGL && !WebGL.isWebGL2Available()) {
    return (
      <div className='flex h-[50vh] flex-col items-center justify-center'>
        <EmptyState title='Oh no!' description={NO_WEBGL_ENABLED_MESSAGE} testId='avatar' />
      </div>
    )
  }

  return (
    <Box className='md:min-h-[calc(100vh-110px)] min-h-[calc(100vh-200px)]'>
      <Drawer
        position='right'
        withOverlay={false}
        opened={isRewardsOpen}
        onClose={() => navigate('/')}
        title={<Title order={2}>{t('Rewards')}</Title>}
      >
        <Rewards />
      </Drawer>
      <Drawer
        position={isTabletOrMobile ? 'bottom' : 'right'}
        withOverlay={false}
        radius={isTabletOrMobile ? 20 : 0}
        {...(isTabletOrMobile && { size: 'sm' })}
        opened={isInventoryOpen}
        onClose={() => navigate('/')}
        size={isMobile ? 270 : 'md'}
        className='mt-2'
        title={<Title order={4}>{t('Avatar')}</Title>}
      >
        {isInventoryOpen && <InventoryModule isMobile={isTabletOrMobile} ref={ref} />}
      </Drawer>
      <Stack justify='space-between' className='md:!flex-row bg-no-repeat bg-cover min-h-[inherit]'>
        <Flex className={clsx('max-w-screen h-[50vh] md:flex-1 md:h-auto md:w-1/2 xl:w-full')} justify='center'>
          <AvatarPreview previewMode={isInventoryOpen} ref={ref} />
        </Flex>
        <Stack visibleFrom='md' mr='lg' maw={400}>
          <RecommendedCategory />
          <LevelInfo onChain={TENANT_CONFIG.levelVersion === LevelUpVersion.V2} />
        </Stack>
        <Carousel height={210} slideGap='xs' hiddenFrom='md' controlsOffset='xs' loop withControls={false}>
          <Carousel.Slide>
            <RecommendedCategory />
          </Carousel.Slide>
          <Carousel.Slide>
            <LevelInfo onChain={TENANT_CONFIG.levelVersion === LevelUpVersion.V2} />
          </Carousel.Slide>
        </Carousel>
      </Stack>
    </Box>
  )
}

export default AvatarView
