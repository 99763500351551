import { useState } from 'react'
import { Icon } from 'summon-ui'
import { FormattedMessage, useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, Button, Paper, Flex, Image, Box } from 'summon-ui/mantine'
import { useViewer } from '@hooks'
import useUpdateLineaGuild from './useUpdateLineaGuild'

type LineaGuildOption = 'Frogwifcat' | 'Linus' | 'Efrogs' | 'Foxy'
const guilds: LineaGuildOption[] = ['Frogwifcat', 'Linus', 'Efrogs', 'Foxy']

interface Props {
  isOpen: boolean
  onSuccess: () => void
  onFinish: () => void
}

const LineaGuildFormModal = ({ isOpen, onSuccess, onFinish }: Props) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const [selectedGuild, setSelectedGuild] = useState<LineaGuildOption | undefined>(
    viewer?.customData?.linea?.guild as LineaGuildOption
  )
  const { updateLineaGuild, isUpdatingLineaGuild } = useUpdateLineaGuild()

  const isAGuildSelected = !!selectedGuild

  const handleSubmit = async () => {
    if (isAGuildSelected) {
      updateLineaGuild({ lineaGuild: selectedGuild.toLocaleLowerCase(), onSuccess, onFinish })
    }
  }

  return (
    <Modal opened={isOpen} onClose={onFinish} maw={430} withCloseButton={false}>
      <Stack p='xs'>
        <Box>
          <Text ta='center' fw='bold' size='lg'>
            {t('Select a Guild')}
          </Text>
          <Box ta='center' c='gray.2'>
            <FormattedMessage
              id='lineaGuildSelectModalFormattedMessage'
              values={{
                note: (
                  <Box component='span' tt='uppercase' fw='bold' c='gray.1'>
                    NOTE
                  </Box>
                )
              }}
            />
          </Box>
        </Box>
        <Stack gap='xs'>
          {guilds.map((guildOption: LineaGuildOption) => {
            const isGuildOptionSelected = guildOption === selectedGuild
            return (
              <Paper
                key={guildOption}
                withBorder
                bg={isGuildOptionSelected ? 'dark.8' : 'black'}
                onClick={() => setSelectedGuild(guildOption)}
                pos='relative'
                radius='md'
              >
                <Flex justify='start' align='center' p='sm' className='cursor-pointer hover:bg-dark-8' gap='sm'>
                  <Image src={`/images/lootdrop/lineaGuilds/${guildOption.toLocaleLowerCase()}.png`} />
                  <Text fw='bold'>{guildOption}</Text>
                </Flex>
                {isGuildOptionSelected && (
                  <Icon
                    radius='100%'
                    name='Check'
                    bg='success.6'
                    variant='filled'
                    pos='absolute'
                    top={-6}
                    right={-10}
                    size='sm'
                  />
                )}
              </Paper>
            )
          })}
        </Stack>
        <Button onClick={handleSubmit} disabled={!isAGuildSelected} loading={isUpdatingLineaGuild} miw={115}>
          {t('Join Guild')}
        </Button>
      </Stack>
    </Modal>
  )
}

export default LineaGuildFormModal
