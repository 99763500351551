/* eslint-disable react/no-unknown-property */
import { type Ref, Suspense, forwardRef, useEffect, useRef, useState, useMemo } from 'react'
import clsx from 'clsx'
import { Spinner } from 'summon-ui'
import { CustomCameraControls, CustomOrbitControls } from '@components'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import { useViewer } from '@hooks'
import { ItemType, type InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import { SlotType } from '@modules/inventory/hooks/useAvatarStore'
import { Environment, useGLTF } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { useCameraStorage } from '../store/camera.store'
import Mesh from './Mesh'

const DEFAULT_ZOOM = 4
const COMPANION_POSITION = [-0.41, -0.02, 0] as [number, number, number]

type Props = {
  className?: string
  items: InventoryItem[]
  preview: boolean
  isEditor?: boolean
  background?: string | undefined
  centerProps: any
}

const Avatar3D = forwardRef(({ className, background, items, preview, centerProps, isEditor = false }: Props, ref) => {
  const { setLerping } = useCameraStorage()
  const avatarRef = useRef(null)
  const { viewer, isLoading: isViewerLoading } = useViewer()
  const { selectedOnboardingSkin } = useOnboardingStorage()
  const [loadingAssets, setLoadingAssets] = useState(true)

  const skin = viewer?.skinId || selectedOnboardingSkin?.glbUrl

  // Preload assets
  useEffect(() => {
    if (loadingAssets && items && skin) {
      useGLTF.preload(skin)
      items.filter((item) => item.isEquipped).forEach((item) => useGLTF.preload(item.glbUrl))
      setLoadingAssets(false)
    }
  }, [items, loadingAssets, skin])

  // Memoized check for headwear to avoid recalculating on each render
  const isHeadwearEquipped = useMemo(
    () => items.some((item) => item.isEquipped && item.slotType === SlotType.Headwear),
    [items]
  )

  // Filter and map through equipped items, hiding hair if headwear is equipped
  const equippedItems = useMemo(
    () =>
      items
        .filter((item) => item.isEquipped && !(item.slotType === SlotType.Hair && isHeadwearEquipped))
        .map((item, index) => {
          const { tokenId, glbUrl, itemType } = item

          if (itemType === ItemType.Companion) {
            return (
              <Mesh
                key={`avatar-equipped-item-companion-${tokenId}-${index}`}
                uri={glbUrl}
                position={COMPANION_POSITION}
                usePrimitive
              />
            )
          }
          return <Mesh key={`avatar-equipped-item-${tokenId}-${index}`} uri={glbUrl} />
        }),
    [items, isHeadwearEquipped]
  )

  if (isViewerLoading) return <Spinner />

  return (
    <Suspense fallback={<Spinner />}>
      <Canvas
        camera={{ zoom: centerProps?.zoom || DEFAULT_ZOOM, fov: 65 }}
        data-testid='avatar-preview'
        onPointerDown={() => setLerping(false)}
        onWheel={() => setLerping(false)}
        className={clsx(className, 'cursor-grabbing')}
        ref={ref as Ref<HTMLCanvasElement>}
        frameloop='always'
        resize={{ debounce: 0 }}
        gl={{ preserveDrawingBuffer: true }}
        shadows
      >
        {!isEditor && (!preview ? <CustomCameraControls rotate={!preview} /> : <CustomOrbitControls />)}
        <Environment files='/avatar-environment-apartment.hdr' />
        <pointLight intensity={0.3} position={[0, 2, 0]} castShadow />
        <pointLight intensity={1} position={[-4, 0, 0]} castShadow />
        <pointLight intensity={2} position={[0, 1, 0]} castShadow />
        {background && <color attach='background' args={[background]} />}

        <group ref={avatarRef} position={centerProps?.position || [0, -0.65, 0]}>
          {!loadingAssets && skin && (
            <>
              <Mesh uri={skin} renderOrder={0} />
              {equippedItems}
            </>
          )}
        </group>
      </Canvas>
    </Suspense>
  )
})

Avatar3D.displayName = 'Avatar3D'
export default Avatar3D
