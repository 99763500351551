import { useRef } from 'react'
import { EnhancedPaper } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Divider, Group, Box, Text, Flex } from 'summon-ui/mantine'
import { TENANT_ONBOARDING } from '@config'
import AvatarWithInventory from '@features/onboarding/components/AvatarWithInventory'
import OnboardingHeader from '@features/onboarding/components/OnboardingHeader'
import useOnboardingUtils from '@features/onboarding/hooks/useOnboardingUtils'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import { useLogout, useAvatarDownloader } from '@hooks'
import useEquipItems from '@modules/inventory/hooks/useEquipItems'

const EquipDNATraits = () => {
  const t = useTranslation()
  const canvas = useRef<HTMLCanvasElement>(null)
  const { uploadAvatar, loading: submittingImage } = useAvatarDownloader()
  const { logout } = useLogout()
  const { completeOnboarding } = useOnboardingUtils()

  const { step } = useOnboardingStorage()
  const submitUser = async () => {
    try {
      await uploadAvatar(canvas.current)
      await completeOnboarding({})
    } catch (error) {
      console.error('Error completing onboarding', error)
    }
  }

  const { equipItems, isEquippingItems } = useEquipItems({ onCallback: submitUser, isDna: true })

  return (
    <>
      <Box mah={100}>
        <Text hiddenFrom='md' fz='xs'>
          STEP {step + 1}/2
        </Text>
        <OnboardingHeader title={TENANT_ONBOARDING.avatar.title} subTitle={TENANT_ONBOARDING.avatar.subtitle} />
        <Box h='100%' pl='sm' mb='100'>
          <AvatarWithInventory isDNATraits={true} ref={canvas}>
            <Flex justify='space-between'>
              <Button miw={120} onClick={logout} variant='outline'>
                {t('Cancel')}
              </Button>
              <Button
                onClick={() => equipItems()}
                miw={120}
                loading={isEquippingItems || submittingImage}
                disabled={isEquippingItems}
              >
                {t('Save')}
              </Button>
            </Flex>
          </AvatarWithInventory>
        </Box>

        <EnhancedPaper withBg w='100%' className='md:absolute fixed bottom-0 left-0' visibleFrom='md'>
          <Divider mb='md' />
          <Group justify='end' px='lg' mb='lg'>
            <Button
              data-testid='select-traits-save-button'
              onClick={() => equipItems()}
              miw={120}
              loading={isEquippingItems || submittingImage}
              disabled={isEquippingItems}
            >
              {t('Save')}
            </Button>
          </Group>
        </EnhancedPaper>
      </Box>
    </>
  )
}

export default EquipDNATraits
