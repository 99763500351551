import { useEffect } from 'react'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import { useCameraStorage } from '@modules/avatar/store/camera.store'
import { SlotType } from './useAvatarStore'

const useCameraActions = (selectedItem: InventoryItem | null) => {
  const { setLerping, setCameraMode } = useCameraStorage()

  useEffect(() => {
    if (selectedItem) {
      setLerping(true)
      setCameraMode(selectedItem.slotType as SlotType)
    } else {
      setLerping(true)
      setCameraMode(SlotType.Unknown)
    }
  }, [selectedItem])
}

export default useCameraActions
