import { useGqlQuery } from '@api/gql/gqlServices'
import { GetDnaItemsDocument, type GetDnaItemsQuery } from '@generated/generates'
import type { SlotType } from '@modules/inventory/hooks/useAvatarStore'

interface Props {
  enabled: boolean
  slotType?: SlotType
}

const useDnaItems = ({ enabled, slotType }: Props) => {
  const { data: dnaItemsData, isLoading: isDnaItemsLoading } = useGqlQuery<GetDnaItemsQuery>(
    GetDnaItemsDocument,
    {
      slotType
    },
    {
      enabled,
      staleTime: Infinity // Data will never be considered stale
    }
  )

  const dnaItems = dnaItemsData?.getDNAItems?.items

  return {
    dnaItems,
    isDnaItemsLoading
  }
}

export default useDnaItems
