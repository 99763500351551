import { useEffect, useRef, useState } from 'react'
import { TENANT_CONFIG } from '@config'
import { useOnboardingStorage } from '@features/onboarding/onboarding.store'
import { useAvatarDownloader } from '@hooks'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import useAvatarStore from './useAvatarStore'
import useCameraActions from './useCameraActions'

const isAbleToShareAvatar = TENANT_CONFIG.features.enableShareAvatar

const useAvatarActions = (isDNA: boolean, searchParams: URLSearchParams) => {
  const selectedElementRef = useRef(null)
  const { uploadAvatar } = useAvatarDownloader()
  const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null)
  const { setSelectedOnboardingSkin, selectedOnboardingSkin } = useOnboardingStorage()
  const { equipItem, unequipItem, previewItems, setRotating, resetPreviewItems, clearCategory } = useAvatarStore()
  useCameraActions(selectedItem)

  const nextStep = (item: InventoryItem) => {
    isDNA ? setSelectedOnboardingSkin(item) : equipItem(item)
  }

  useEffect(() => {
    if (searchParams.get('slot')) {
      // @ts-ignore
      selectedElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [selectedElementRef, searchParams])

  useEffect(() => () => resetPreviewItems(), [])

  return {
    nextStep,
    equipItem,
    setRotating,
    unequipItem,
    previewItems,
    uploadAvatar,
    selectedItem,
    clearCategory,
    setSelectedItem,
    selectedElementRef,
    isAbleToShareAvatar,
    selectedOnboardingSkin
  }
}

export default useAvatarActions
