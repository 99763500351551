import { useMemo } from 'react'
import type { Maybe } from '@generated/generates'
import type { InventoryItem } from '@hooks/inventory/useAvatarMetadata'
import { SlotType } from './useAvatarStore'

const DNA_SLOT_TYPES = [SlotType.Body]

const useGroupedItems = (items: InventoryItem[], previewItems: InventoryItem[], isDNA: boolean) => {
  return useMemo(() => {
    const itemsToGroup = (isDNA ? items : previewItems)?.filter((item) =>
      isDNA ? DNA_SLOT_TYPES.includes(item.slotType as SlotType) : item.slotType !== SlotType.Body
    )

    const groupedItems = itemsToGroup.reduce(
      (acc: Record<number, InventoryItem[]>, item: InventoryItem) => {
        const key: Maybe<number | undefined> = item.slotType

        // Ensure the key is neither null nor undefined
        if (key !== null && key !== undefined) {
          // If this slot type doesn't exist yet, initialize it as an empty array
          if (!acc[key]) {
            acc[key] = []
          }
          // Push the item into the array for this slotType
          acc[key].push(item)
        }

        return acc
      },
      {} as Record<number, InventoryItem[]>
    )

    return groupedItems
  }, [items, previewItems, isDNA])
}

export default useGroupedItems
